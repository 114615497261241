<template>
    <router-view />
</template>

<script>
// import websocket from "./utils/websocket";
import { computed } from 'vue'

export default {
    data() {
        return {
            ws: "",
            conn_id: '',
            //心跳检测
        };
    },
    provide() {
        return {
            conn_id: computed(() => this.conn_id),
        }
    },
    created() {
        // websocket.Init();
        // this.ws = websocket.getWebSocket();
        // this.ws.onmessage =  (e) => {
        //     console.log("接收消息:" + e.data);
        //     websocket.heartCheck.start();
        //     if (e.data == "ok") {
        //         //心跳消息不做处理
        //         return;
        //     }
        //     this.messageHandle(e.data);
        // };
    },
    methods: {
        //根据消息标识做不同的处理
        messageHandle(message) {
            let msg = JSON.parse(message);
            switch (msg.action) {
                case "sync":
                    console.log("首次消息");
                    console.log(this.conn_id);
                    this.conn_id = msg.conn_id;
                    console.log(this.conn_id);
                    break;
                case "group_state":
                    console.log("状态通知");
                    break;
                default:
                    console.log("未知消息类型");
            }
        },
    },
};
</script>

<style>
@import "./assets/css/main.css";
@import "./assets/css/color-dark.css";
</style>
