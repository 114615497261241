"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var App_vue_1 = require("./App.vue");
var router_1 = require("./router");
var store_1 = require("./store");
var element_1 = require("./plugins/element");
require("./assets/css/icon.css");
var app = (0, vue_1.createApp)(App_vue_1.default);
(0, element_1.default)(app);
app
    .use(store_1.default)
    .use(router_1.default)
    .mount('#app');
