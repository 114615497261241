import request from '../utils/request';
import { baseURL } from "../napis/base";

export const fetchData = (query, api, type) => {

    var data = '';

    if (localStorage.getItem('adminInfo') !== null) {
        // 服务端拿的 token 可以从 vuex、localStorage 等地方取
        request.defaults.headers['token'] = JSON.parse(localStorage.getItem('adminInfo')).token;
    } else {
        request.defaults.headers['token'] = ``;
    }
    if (type === "POST" || type === "post") {
        data = query;
        query = '';
    }

    return request({
        url: baseURL + api.trim(),
        method: type,
        params: query,
        data: data
    });
};
