"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.service = exports.baseURL = void 0;
var axios_1 = require("axios");
exports.baseURL = 'https://admin.guocode.com';
// export const baseURL = 'http://192.168.50.43:20002'
// export const baseURL = 'https://api.guokid.com'
exports.service = axios_1.default.create({
    // process.env.NODE_ENV === 'development' 来判断是否开发环境
    // easy-mock服务挂了，暂时不使用了
    // baseURL: 'https://www.easy-mock.com/mock/592501a391470c0ac1fab128',
    timeout: 5000000,
    baseURL: exports.baseURL
});
exports.service.interceptors.request.use(function (cfg) {
    var _a, _b;
    var str = (_a = localStorage.getItem('adminInfo')) !== null && _a !== void 0 ? _a : "{}";
    cfg.headers['token'] = (_b = JSON.parse(str).token) !== null && _b !== void 0 ? _b : "";
    return cfg;
});
exports.service.interceptors.response.use(function (response) {
    if (response.status === 200) {
        return response.data;
    }
    else {
        alert(response.data.message);
        Promise.reject();
    }
}, function (error) {
    // if (error.response.data.message == '请重新登录') {
    //     // localStorage.removeItem("ms_username");
    //     // localStorage.removeItem("adminInfo");
    //     location.reload();
    // } else
    if (error.response.data.code == 499) {
        localStorage.removeItem("ms_username");
        localStorage.removeItem("adminInfo");
        location.reload();
        alert(error.response.data.message);
        window.alert = function () { return false; };
    }
    else {
        alert(error.response.data.message);
    }
    return Promise.reject(error);
});
