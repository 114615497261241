"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var axios_1 = require("axios");
var service = axios_1.default.create({
    // process.env.NODE_ENV === 'development' 来判断是否开发环境
    // easy-mock服务挂了，暂时不使用了
    // baseURL: 'https://www.easy-mock.com/mock/592501a391470c0ac1fab128',
    timeout: 5000000
});
service.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
service.interceptors.request.use(function (config) {
    return config;
}, function (error) {
    return Promise.reject(error);
});
service.interceptors.response.use(function (response) {
    if (response.status === 200) {
        return response.data;
    }
    else {
        alert(response.data.message);
        Promise.reject();
    }
}, function (error) {
    var _a, _b, _c, _d, _e;
    // if (error.response.data.message == '请重新登录') {
    //     // localStorage.removeItem("ms_username");
    //     // localStorage.removeItem("adminInfo");
    //     location.reload();
    // } else
    var code = (_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.code;
    var msg = (_e = (_d = (_c = error.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.message) !== null && _e !== void 0 ? _e : error.toString();
    if (code == 499) {
        localStorage.removeItem("ms_username");
        localStorage.removeItem("adminInfo");
        location.reload();
        alert(msg);
        console.log(error);
        window.alert = function () { return false; };
    }
    else {
        alert(msg);
    }
    return Promise.reject(error);
});
exports.default = service;
