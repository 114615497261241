import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
    {
        path: '/',
        redirect: '/dashboard'
    }, {
        path: "/",
        name: "Home",
        component: Home,
        children: [
            {
                path: "/dashboard",
                name: "dashboard",
                meta: {
                    title: '系统首页'
                },
                component: () => import(
                    /* webpackChunkName: "dashboard" */
                    "../views/Dashboard.vue")
            }, {
                path: "/table",
                name: "basetable",
                meta: {
                    title: '表格'
                },
                component: () => import(
                    /* webpackChunkName: "table" */
                    "../views/BaseTable.vue")
            }, {
                path: "/bureau",
                name: "bureau",
                meta: {
                    title: '机构'
                },
                component: () => import(
                    /* webpackChunkName: "table" */
                    "../views/bureau/Bureau.vue")
            }, {
                path: "/courseActionPhoto",
                name: "courseActionPhoto",
                meta: {
                    title: '课程动作图组'
                },
                component: () => import(
                    /* webpackChunkName: "table" */
                    "../views/actionPhoto/index.vue")
            }, {
                path: "/au",
                name: "active-users",
                meta: {
                    title: '活跃用户'
                },
                component: () => import(
                    "../views/au/AU.vue")
            }, {
                path: "/activeStudentAccountStats",
                name: "activeStudentAccountStats",
                meta: {
                    title: '学生账户活跃统计'
                },
                component: () => import(
                    "../views/au/ActiveStudentAccountAmount.vue")
            }, {
                path: "/activeParentAccountStats",
                name: "activeParentAccountStats",
                meta: {
                    title: '家长账户活跃统计'
                },
                component: () => import(
                    "../views/au/ActiveParentAccountAmount.vue")
            }, {
                path: "/activatedAccountStats",
                name: "activatedAccountStats",
                meta: {
                    title: '激活账户历史统计'
                },
                component: () => import(
                    "../views/au/ActivatedAccountHistoricalStats.vue")
            }, {
                path: "/bureau-count",
                name: "bureau-count",
                meta: {
                    title: '机构幼儿园统计'
                },
                component: () => import(
                    /* webpackChunkName: "table" */
                    "../views/bureau/BureauCount.vue")
            }, {
                path: "/helper",
                name: "helper",
                meta: {
                    title: '服务人员'
                },
                component: () => import(
                    /* webpackChunkName: "table" */
                    "../views/Helper.vue")
            }, {
                path: "/goods",
                name: "goods",
                meta: {
                    title: '套餐'
                },
                component: () => import(
                    /* webpackChunkName: "table" */
                    "../views/Goods.vue")
            },
            {
                path: "/agency",
                name: "agency",
                meta: {
                    title: '代理'
                },
                component: () => import(
                    /* webpackChunkName: "table" */
                    "../views/Agency.vue")
            },
            {
                path: "/course",
                name: "course",
                meta: {
                    title: '课程'
                },
                component: () => import(
                    /* webpackChunkName: "table" */
                    "../views/Course.vue")
            }, {
                path: "/withdrawal",
                name: "withdrawal",
                meta: {
                    title: '提现列表'
                },
                component: () => import(
                    /* webpackChunkName: "table" */
                    "../views/Withdrawal.vue")
            }, {
                path: "/lesson",
                name: "lesson",
                meta: {
                    title: '课时'
                },
                component: () => import(
                    /* webpackChunkName: "table" */
                    "../views/Lesson.vue")
            }, {
                path: "/video",
                name: "video",
                meta: {
                    title: '视频'
                },
                component: () => import(
                    /* webpackChunkName: "table" */
                    "../views/Video.vue")
            }, {
                path: "/listCreditRec",
                name: "listCreditRec",
                meta: {
                    title: '视频'
                },
                component: () => import(
                    /* webpackChunkName: "table" */
                    "../views/ListCreditRec.vue")
            }, {
                path: "/charts",
                name: "basecharts",
                meta: {
                    title: '图表'
                },
                component: () => import(
                    /* webpackChunkName: "charts" */
                    "../views/BaseCharts.vue")
            }, {
                path: "/form",
                name: "baseform",
                meta: {
                    title: '表单'
                },
                component: () => import(
                    /* webpackChunkName: "form" */
                    "../views/BaseForm.vue")
            }, {
                path: "/tabs",
                name: "tabs",
                meta: {
                    title: 'tab标签'
                },
                component: () => import(
                    /* webpackChunkName: "tabs" */
                    "../views/Tabs.vue")
            }, {
                path: "/donate",
                name: "donate",
                meta: {
                    title: '鼓励作者'
                },
                component: () => import(
                    /* webpackChunkName: "donate" */
                    "../views/Donate.vue")
            }, {
                path: "/permission",
                name: "permission",
                meta: {
                    title: '权限管理',
                    permission: true
                },
                component: () => import(
                    /* webpackChunkName: "permission" */
                    "../views/Permission.vue")
            }, {
                path: "/i18n",
                name: "i18n",
                meta: {
                    title: '国际化语言'
                },
                component: () => import(
                    /* webpackChunkName: "i18n" */
                    "../views/I18n.vue")
            }, {
                path: "/upload",
                name: "upload",
                meta: {
                    title: '上传插件'
                },
                component: () => import(
                    /* webpackChunkName: "upload" */
                    "../views/Upload.vue")
            }, {
                path: "/icon",
                name: "icon",
                meta: {
                    title: '自定义图标'
                },
                component: () => import(
                    /* webpackChunkName: "icon" */
                    "../views/Icon.vue")
            }, {
                path: '/404',
                name: '404',
                meta: {
                    title: '找不到页面'
                },
                component: () => import(/* webpackChunkName: "404" */
                    '../views/404.vue')
            }, {
                path: '/403',
                name: '403',
                meta: {
                    title: '没有权限'
                },
                component: () => import(/* webpackChunkName: "403" */
                    '../views/403.vue')
            }, {
                path: '/Fission',
                name: 'Fission',
                meta: {
                    title: '裂变概览'
                },
                component: () => import(/* webpackChunkName: "403" */
                    '../views/Fission.vue')
            }, {
                path: '/discount',
                name: 'discount',
                meta: {
                    title: '折扣'
                },
                component: () => import(/* webpackChunkName: "403" */
                    '../views/Discount.vue')
            },
            {
                path: '/codeValidate',
                name: 'codeValidate',
                meta: {
                    title: '代码检测'
                },
                component: () => import(/* webpackChunkName: "403" */
                    '../views/CodeValidate.vue')
            },
            {
                path: '/createLessonCon',
                name: 'createLessonCon',
                meta: {
                    title: '创建课时内容'
                },
                component: () => import(/* webpackChunkName: "403" */
                    '../views/lesson/CreateLessonCon.vue')
            },
            {
                path: '/temResource',
                name: 'temResource',
                meta: {
                    title: '模板列表'
                },
                component: () => import(/* webpackChunkName: "403" */
                    '../views/template/TemResource.vue')
            },
            {
                path: '/tagManage',
                name: 'tagManage',
                meta: {
                    title: '标签管理'
                },
                component: () => import(/* webpackChunkName: "403" */
                    '../views/TagManage.vue')
            },
            {
                path: '/superUserList',
                name: 'superUserList',
                meta: {
                    title: '超级用户列表'
                },
                component: () => import(/* webpackChunkName: "403" */
                    '../views/superUser/Userlist.vue')
            },
            {
                path: '/superRole',
                name: 'superRole',
                meta: {
                    title: '超级用户角色'
                },
                component: () => import(/* webpackChunkName: "403" */
                    '../views/superUser/Rolelist.vue')
            },
            {
                path: '/superPath',
                name: 'superPath',
                meta: {
                    title: 'Api路径'
                },
                component: () => import(/* webpackChunkName: "403" */
                    '../views/superUser/Pathlist.vue')
            },
            {
                path: '/object',
                name: 'object',
                meta: {
                    title: '操作对象'
                },
                component: () => import(/* webpackChunkName: "403" */
                    '../views/superUser/Object.vue')
            },
            {
                path: '/menu',
                name: 'menu',
                meta: {
                    title: '菜单管理'
                },
                component: () => import(/* webpackChunkName: "403" */
                    '../views/Menu.vue')
            },
            {
                path: '/dealer',
                name: 'dealer',
                meta: {
                    title: '经销人管理'
                },
                component: () => import(/* webpackChunkName: "403" */
                    '../views/Dealer.vue')
            },
            {
                path: '/test/index',
                name: 'testIndex',
                meta: {
                    title: '测试文件'
                },
                component: () => import(/* webpackChunkName: "403" */
                    '../views/test/index.vue')
            },
            {
                path: '/activeTemplate',
                name: 'activeTemplate',
                meta: {
                    title: '活动模板管理'
                },
                component: () => import(/* webpackChunkName: "403" */
                    '../views/active/ActiveTemplate.vue')
            },
            {
                path: '/activeType',
                name: 'activeType',
                meta: {
                    title: '活动类型管理'
                },
                component: () => import(/* webpackChunkName: "403" */
                    '../views/active/ActiveType.vue')
            },
            {
                path: '/pointGroup',
                name: 'pointGroup',
                meta: {
                    title: '知识点管理'
                },
                component: () => import(/* webpackChunkName: "403" */
                    '../views/PointGroup.vue')
            },
            {
                path: '/fissionSetting',
                name: 'fissionSetting',
                meta: {
                    title: '裂变端设置'
                },
                component: () => import(/* webpackChunkName: "403" */
                    '../views/fission/Setting.vue')
            },
            {
                path: '/vipList',
                name: 'vipList',
                meta: {
                    title: 'vip列表'
                },
                component: () => import(/* webpackChunkName: "403" */
                    '../views/vipList/VipList')
            },
            {
                path: '/withdrawDeposit',
                name: 'withdrawDeposit',
                meta: {
                    title: '审核'
                },
                component: () => import(/* webpackChunkName: "403" */
                    '../views/withdrawDeposit/WithdrawDeposit')
            },
            {
                path: '/appletAppHelpList',
                name: 'appletAppHelpList',
                meta: {
                    title: '帮助列表'
                },
                component: () => import(/* webpackChunkName: "403" */
                    '../views/appletAppHelpList/appletAppHelpList')
            },
            {
                path: '/listServiceIntro',
                name: 'listServiceIntro',
                meta: {
                    title: '服务列表'
                },
                component: () => import(/* webpackChunkName: "403" */
                    '../views/listServiceIntro/listServiceIntro')
            },
            {
                path: '/TeachingPlan',
                name: 'TeachingPlan',
                meta: {
                    title: '教案模块'
                },
                component: () => import(/* webpackChunkName: "403" */
                    '../views/teachingPlan/TeachingPlan')
            },
            {
                path: '/Statistics',
                name: 'Statistics',
                meta: {
                    title: '统计数据'
                },
                component: () => import(/* webpackChunkName: "403" */
                    '../views/Statistics.vue')
            },
            {
                path: '/GbCustomer',
                name: 'GbCustomer',
                meta: {
                    title: '统计数据'
                },
                component: () => import(/* webpackChunkName: "403" */
                    '../views/gbCustomer/GbCustomer.vue')
            },
            {
                path: '/CircleOfFriends',
                name: 'CircleOfFriends',
                meta: {
                    title: '朋友圈设置'
                },
                component: () => import(/* webpackChunkName: "403" */
                    '../views/circleOfFriends/circleOfFriends.vue')
            }
        ]
    }, {
        path: "/login",
        name: "Login",
        meta: {
            title: '登录'
        },
        component: () => import(
            /* webpackChunkName: "login" */
            "../views/Login.vue")
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title} | 果宝编程后台`;
    const role = localStorage.getItem('ms_username');
    if (!role && to.path !== '/login') {
        next('/login');
    } else if (to.meta.permission) {
        // 如果是管理员权限则可进入，这里只是简单的模拟管理员权限而已
        role === 'admin'
            ? next()
            : next('/403');
    } else {
        next();
    }
});

export default router;
